import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img0 from "../images/design-library-00.png";
import img1 from "../images/design-library-01.png";
import img2 from "../images/design-library-02.png";
import img3 from "../images/design-library-03.png";
import img4 from "../images/design-library-04.png";
import img5 from "../images/design-library-05.png";
import img6 from "../images/design-library-06.png";
import img7 from "../images/design-library-07.png";

const PortfolioPage = () => (
  <Layout>
    <SEO
      title="Javier Suzuki | Design & Front-end Development Portfolio - Design System"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `application`, `ai`, `artificial-intelligence`, `ml`, `machine-learning`, `react`]}
    />
    <div className="portfolio-header">
      <h1>Design Assets</h1>
      <h5>Presentation Design</h5>
      <p>Design of Google Slides & Docs Templates and other communication assets aligned with the company's brand guideline.</p>
    </div>
    {/* <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://wushka.com.au" target="_blank" rel="noopener noreferrer">View Project</a>
    </div> */}
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Internal Google Site</figcaption>
        <img src={img0} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Company Google Slides</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Activity Detail Page</figcaption> */}
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Activity Detail Page - Models Performance Comparison</figcaption> */}
        <img src={img3} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Activity Detail Page - Models Performance Comparison</figcaption> */}
        <img src={img4} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Create New Activity Form</figcaption> */}
        <img src={img5} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Create New Activity Form</figcaption> */}
        <img src={img6} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Create New Activity Form</figcaption> */}
        <img src={img7} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
